import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App min-h-screen bg-gray-900 text-white p-6">
      <div className="container mx-auto max-w-4xl py-12 space-y-24">

        {/* Section 1: Generative tooling for the modern web */}
        <section className="text-center space-y-6">
          <h1 className="text-5xl font-bold">
            Generative Tooling for the Modern Web
          </h1>
          <p className="text-xl text-gray-300">
            Empowering developers and creators with advanced generative solutions to innovate and excel in the AI-enhanced era.
          </p>
        </section>

        {/* Section 2: Try our apps */}
        <section className="text-center space-y-6">
          <h2 className="text-4xl font-bold">
            Try Our Apps
          </h2>
          <div className="flex flex-col items-center space-y-4">
            <a
              href="https://app.samsar.one"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:underline text-2xl"
            >
              Samsar.one
            </a>
            <div className='font-bold mb-2'>
            All-in-One Video Editing Toolkit: Combines the best generative models into one easy to use studio and express platform.
            </div>
            {/* Make the iframe clickable by wrapping it in an anchor tag */}
            <a
              href="https://www.producthunt.com/posts/samsar-one"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full h-64"
            >
              <div className="w-full h-full bg-gray-800 rounded-lg overflow-hidden">
                {/* Updated Preview Image */}
                <iframe
                  src="https://cards.producthunt.com/cards/products/658517"
                  className="w-full h-full"
                  style={{ border: 'none' }}
                  frameBorder="0"
                  scrolling="no"
                  allowFullScreen
                ></iframe>
              </div>
            </a>
          </div>
        </section>

        {/* Section 3: Contact us */}
        <section className="text-center space-y-6">
          <h2 className="text-4xl font-bold">
            Want a Generative Tool Built for Your Infrastructure?
          </h2>
          <p className="text-xl text-gray-300">
            Contact us to discuss custom solutions tailored to your specific needs and infrastructure.
          </p>
          <a
            href="mailto:roy@kashmerelabs.com"
            className="inline-block bg-green-600 text-white py-3 px-6 rounded-lg font-semibold hover:bg-green-700 transition-colors"
          >
            Contact Us
          </a>
        </section>

      </div>
    </div>
  );
}

export default App;
